import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from 'react';
import { render } from 'react-dom';
import { msalConfig } from './config/authConfig';
import App from './containers/App/App';

//needs to be initialized outside of root component to ensure it is not re-initialized on re-renders
const msalInstance = new PublicClientApplication(msalConfig);

render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
