import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import fetchMethodRequest from '../../../config/service';
import "../../../scss/scoringDetails.scss"

import { reduxForm } from 'redux-form';
import Loader from '../../App/Loader';
class ScoringDetailsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = async () => {
        this.getDateFromServer()
        this.intervalId = setInterval(() => {
            this.getDateFromServer();
        }, 10000);
        console.log("this.props.ScoringDetailsRecord", this.props.ScoringDetailsRecord)
        let data = this.props.emailTemplateData;
        if (document.getElementById('templateText')) {
            document.getElementById('templateText').innerHTML = data['templateText']
        }
    }

    componentWillUnmount() {
        // Cleanup interval
        clearInterval(this.intervalId);
    }

    getDateFromServer = async () => {
        if (!this.state.ScoringRecord || (this.state.ScoringRecord && this.state.ScoringRecord.ScoringStatus !== "Completed")) {
            fetchMethodRequest('GET', `api/seller/getscoringdetails/${this.props.ScoringDetailsRecord._id}`)
                .then(async (response) => {
                    if (response) {
                        console.log("response", response)
                        this.setState({ ScoringRecord: response })
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }

    render() {
        return (
            <div style={{ display: 'flex' }}>
                <Loader loader={this.state.isLoading} />
                <Modal isOpen={this.props.isScoringDetailsModal}
                    className={` modal-dialog--primary modal-dialog--header `}
                    style={{ maxWidth: "1500px" }}
                >
                    <ModalHeader className="modal__header viewModalHeader" >
                        Scoring Details
                        <button className="lnr lnr-cross modal__close-btn" type="button"
                            onClick={this.props.closeScoringDetailsModal} />
                    </ModalHeader>
                    <ModalBody >
                        <div className="scoring-table-container">
                            <h2 className="table-title">{this.state.ScoringRecord ? `${this.state.ScoringRecord.estateName} - (${this.state.ScoringRecord.EstateID})` : ""}</h2>
                            <table className="scoring-table">
                                <thead>
                                    <tr>
                                        <th>Scoring Step</th>
                                        <th>Scoring Collections</th>
                                        <th>Records Completed</th>
                                        <th>Last Update DateTime CT</th>
                                    </tr>
                                </thead>
                                {this.state.ScoringRecord ?
                                    <tbody>
                                        <tr>
                                            <td>{"Scoring Started"}</td>
                                            <td>{""}</td>
                                            <td>{""}</td>
                                            <td>{new Date(this.state.ScoringRecord.scoringDateTime).toISOString().slice(0, 19).replace('T', ' ')}</td>
                                        </tr>
                                        <tr>
                                            <td>{"Regular Scoring -Layer1"}</td>
                                            <td>{this.state.ScoringRecord.layer1CollectionName}</td>
                                            <td>{this.state.ScoringRecord.layer1}</td>
                                            <td>{this.state.ScoringRecord.layer1lastUpdatedDate}</td>
                                        </tr>
                                        <tr>
                                            <td>{"Regular Scoring -Layer2"}</td>
                                            <td>{this.state.ScoringRecord.layer2CollectionName}</td>
                                            <td>{this.state.ScoringRecord.layer2}</td>
                                            <td>{this.state.ScoringRecord.layer2lastUpdatedDate}</td>
                                        </tr>
                                        <tr>
                                            <td>{"Address Layer 3"}</td>
                                            <td>{this.state.ScoringRecord.layer3CollectionName}</td>
                                            <td>{this.state.ScoringRecord.layer3}</td>
                                            <td>{this.state.ScoringRecord.layer3lastUpdatedDate}</td>
                                        </tr>
                                        <tr>
                                            <td>{"Address Layer 4"}</td>
                                            <td>{this.state.ScoringRecord.layer4CollectionName}</td>
                                            <td>{this.state.ScoringRecord.layer4}</td>
                                            <td>{this.state.ScoringRecord.layer4lastUpdatedDate}</td>
                                        </tr>
                                        <tr>
                                            <td>{"Confirmed Onsite Updates"}</td>
                                            <td>{this.state.ScoringRecord.dedupCollectionName}</td>
                                            <td>{this.state.ScoringRecord.confirmedOnSite}</td>
                                            <td>{this.state.ScoringRecord.confirmedOnSitelastUpdatedDate}</td>
                                        </tr>
                                        <tr>
                                            <td>{"Merge"}</td>
                                            <td>{"ns_scored_properties"}</td>
                                            <td>{this.state.ScoringRecord.merge}</td>
                                            <td>{this.state.ScoringRecord.mergelastUpdatedDate}</td>
                                        </tr>
                                        <tr>
                                            <td>{"Post-Scoring - Prop Min and Max updates"}</td>
                                            <td>{""}</td>
                                            <td>{this.state.ScoringRecord.postProcessing}</td>
                                            <td>{this.state.ScoringRecord.postProcessinglastUpdatedDate}</td>
                                        </tr>
                                        <tr>
                                            <td>{"Status"}</td>
                                            <td>{""}</td>
                                            <td>{this.state.ScoringRecord.ScoringStatus}</td>
                                            <td>{this.state.ScoringRecord.ScoringStatus === "Completed" ? this.state.ScoringRecord.postProcessinglastUpdatedDate : ""}</td>
                                        </tr>
                                    </tbody> : ""}
                            </table>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ScoringDetailsModal = reduxForm({
    form: "ScoringDetailsModal Form", // a unique identifier for this form
    enableReinitialize: true,
})(ScoringDetailsModal);

export default withTranslation('common')(ScoringDetailsModal);