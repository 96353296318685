import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import QueryConfiguration from './components/QueryConfiguration';

const queryConfiguration = () => (
  <div>
    <QueryConfiguration />
  </div>
);

queryConfiguration.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(queryConfiguration);
